'use client';
import Error from 'next/error';
import React from 'react';

export default (): React.ReactElement => {
  return (
    <html lang="en">
      <body>
        <Error statusCode={404} />
      </body>
    </html>
  );
};
